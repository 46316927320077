import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { DynamicFormField } from '@teamfoster/dynamic-forms';

@Component({
  selector: 'app-form-hidden-field',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './form-hidden-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormHiddenFieldComponent {
  config!: DynamicFormField;
  group!: UntypedFormGroup;
}
