import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShowCookieSettings } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryNgrxModule, FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { MenuItem } from '@teamfoster/sdk/menu-ngrx';
import * as fromAuth from '../../auth/store';
import { CommonModule } from '@angular/common';
import { IconModule } from '@teamfoster/sdk/icon';
import { RouterModule } from '@angular/router';
import { Address } from 'src/app/addresses/models';
import { ClearCompletedProfile } from 'src/app/settings/store';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, IconModule, RouterModule, DictionaryNgrxModule],
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  @Input() schema: any;
  @Input() menu: MenuItem[] = [];
  @Input() socials: [] = [];
  @Input() domain: string = '';
  @Input() isSignedIn?: boolean;
  @Input() address?: Address | null;

  // signedIn$ = this.store.selectSignal(fromAuth.getAuthSignedIn);
  year = new Date().getFullYear();

  private dict = new FromDictionaryPipe(this.lang);

  constructor(
    private store: Store,
    private lang: LanguageService
  ) {}

  ngOnInit(): void {
    this.socials.forEach(s => {
      const socialLink = s; // to do config with social buttons
      this.schema.sameAs.push(socialLink);
    });
  }

  openCookie() {
    this.store.dispatch(ShowCookieSettings());
  }
  signOut() {
    this.store.dispatch(ClearCompletedProfile());
    this.store.dispatch(fromAuth.SignOut({}));
  }

  get addressKnown() {
    return !!this.address?.houseNumber;
  }
}
