import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CookieSettingsGuard } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryGuard } from '@teamfoster/sdk/dictionary-ngrx';
import { MenuGuard } from '@teamfoster/sdk/menu-ngrx';
import { NotFoundComponent } from './404.component';
import { AddressGuard, WasteCardNumberGuard } from './addresses/guards';
import { AuthGuard } from './auth/guards/auth.guard';
import { NoAuthGuard } from './auth/guards/no-auth.guard';
import { AddressAuthGuard } from './addresses/guards/address-auth.guard';
import { InitNotificationsGuard } from './notifications/guards';
import { TipGuard } from './tips/guards';
import { SettingsProfileGuard } from './settings/guards';
import { CheckAuthGuard, InsightCodeGuard } from './auth/guards';

const appGuards = [CookieSettingsGuard, DictionaryGuard, MenuGuard, InitNotificationsGuard];

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/dashboard',
  },
  {
    path: 'verhalen',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
    canActivate: [...appGuards, AddressGuard, WasteCardNumberGuard, AddressAuthGuard],
    data: {
      'content-type': 'verhalen',
    },
  },
  {
    path: 'pagina',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
    canActivate: [...appGuards, AddressGuard, WasteCardNumberGuard, AddressAuthGuard],
    data: {
      'content-type': 'pagina',
    },
  },
  {
    path: 'aanmelden',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [...appGuards, NoAuthGuard],
    data: {
      hideBottomNav: true,
    },
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [...appGuards, AddressGuard, WasteCardNumberGuard, AddressAuthGuard, CheckAuthGuard],
  },
  {
    path: 'inzamelkalender',
    loadChildren: () => import('./waste-calendar/waste-calendar.module').then(m => m.WasteCalendarModule),
    canActivate: [...appGuards, AddressGuard, WasteCardNumberGuard, AddressAuthGuard, TipGuard],
  },
  {
    path: 'tips',
    loadChildren: () => import('./tips/tip.module').then(m => m.TipModule),
    canActivate: [...appGuards, AddressGuard, WasteCardNumberGuard, AddressAuthGuard],
  },
  {
    path: 'veelgestelde-vragen',
    loadChildren: () => import('./faq/faq.module').then(m => m.FAQModule),
    canActivate: [...appGuards, AddressGuard, WasteCardNumberGuard, AddressAuthGuard],
  },
  {
    path: 'inzicht',
    loadChildren: () => import('./insight/insight.module').then(m => m.InsightModule),
    canActivate: [...appGuards, AuthGuard, AddressGuard, WasteCardNumberGuard, InsightCodeGuard, AddressAuthGuard],
  },
  {
    path: 'producten',
    loadChildren: () => import('./products/products.module').then(m => m.ProductsModule),
    canActivate: [...appGuards, AddressGuard, WasteCardNumberGuard, AddressAuthGuard],
  },
  {
    path: 'styleguide',
    loadChildren: () => import('./style-guide/style-guide.module').then(m => m.StyleGuideModule),
    canActivate: [],
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then(m => m.MenuModule),
    canActivate: [...appGuards, AddressGuard, WasteCardNumberGuard, AddressAuthGuard],
  },
  {
    path: 'scheidingswijzer',
    loadChildren: () => import('./waste-guide/waste-guide.module').then(m => m.WasteGuideModule),
    canActivate: [...appGuards],
  },
  {
    path: 'instellingen',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canActivate: [...appGuards, AddressGuard, WasteCardNumberGuard, AddressAuthGuard],
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [...appGuards],
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
    canActivate: [...appGuards, AddressGuard, WasteCardNumberGuard, AddressAuthGuard],
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [...appGuards],
  },
  {
    path: 'auth',
    redirectTo: '/aanmelden/inloggen',
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'disabled', bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
