import { Component, Renderer2, inject, computed, effect, signal, PLATFORM_ID, NgZone, ChangeDetectorRef, OnInit } from '@angular/core';

import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd, RouterOutlet, NavigationStart, NavigationCancel } from '@angular/router';
import { Store } from '@ngrx/store';
import { getDictionaryLoaded } from '@teamfoster/sdk/dictionary-ngrx';
import { toSignal } from '@angular/core/rxjs-interop';

import * as navActions from './store/actions/nav.action';
import * as navSelectors from './store/selectors/nav.selector';
import { filter } from 'rxjs/operators';
import { routerFade } from './animations';
import { ChangeDetectionStrategy } from '@angular/core';
import { CookieConfigService, getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { getRouterState } from './store';
import { getMenuLoaded } from '@teamfoster/sdk/menu-ngrx';
import { SignOut, getAuthSignedIn } from './auth/store';
import { getAddress } from './addresses/store';
import { Platform } from '@angular/cdk/platform';

import { environment } from 'src/environments/environment';
import { initializeApp } from 'firebase/app';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { asapScheduler } from 'rxjs';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { App, URLOpenListenerEvent } from '@capacitor/app';

import { Keyboard } from '@capacitor/keyboard';

import { AppTrackingTransparency, AppTrackingStatusResponse } from 'capacitor-plugin-app-tracking-transparency';
import { AppTrackingService } from './core/services/app-tracking.service';
import { getHasDepositsMunicipalities } from './insight/store';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [routerFade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  private store = inject(Store);
  private router = inject(Router);
  private renderer = inject(Renderer2);
  private zone = inject(NgZone);
  private document: any = inject(DOCUMENT);
  private cookieConfig = inject(CookieConfigService); // cookie
  private appTracking = inject(AppTrackingService);

  public platform = inject(Platform);
  private platformId = inject(PLATFORM_ID);

  isStandAlone$ = signal(false);
  routeState$ = this.store.selectSignal(getRouterState);
  menuOpen$ = this.store.selectSignal(navSelectors.getMenuOpen);
  cookiePrefs$ = this.store.selectSignal(getCookiePreferences);
  dictionaryLoaded$ = this.store.selectSignal(getDictionaryLoaded);
  menuLoaded$ = this.store.selectSignal(getMenuLoaded);
  trackingStatus$ = this.appTracking.status$;

  address$ = this.store.selectSignal(getAddress);
  signedIn$ = this.store.selectSignal(getAuthSignedIn);

  hasInsights$ = this.store.selectSignal(getHasDepositsMunicipalities);

  private routerEvents$ = toSignal(
    this.router.events.pipe(filter(e => [NavigationStart, NavigationEnd, NavigationCancel].some(b => e instanceof b)))
  );
  loading$ = computed(() => !(this.routerEvents$() instanceof NavigationEnd || this.routerEvents$() instanceof NavigationCancel));
  loaded$ = computed(() => this.dictionaryLoaded$() && this.menuLoaded$());

  hideSplashscreen = effect(() => {
    const loaded = this.loaded$();

    if (loaded) {
      SplashScreen.hide();
    }
  });

  domainName: string = '';
  testGridEnabled = false;
  routerAnimationState = '';

  private previousPath = '';

  keyboardVisible = false;
  hideBottomBar = false;

  cd = inject(ChangeDetectorRef);

  menuOpenEffect$ = effect(() => this.updateRoot(this.menuOpen$()));
  routerEffect$ = effect(() => {
    const event = this.routerEvents$();
    const state = this.routeState$();

    // if (event instanceof NavigationStart && (event as NavigationStart).navigationTrigger === 'imperative') {
    //   if (!state?.state?.queryParams) {
    //     window.scrollTo(0, 0);
    //   }
    // }
    if (event instanceof NavigationEnd && this.cookiePrefs$()?.analytical && this.trackingStatus$() === 'authorized') {
      // console.log(this.trackingStatus$());
      if ((<any>window).gtag) {
        (<any>window).gtag('config', this.cookieConfig.analyticsCode, { page_path: event.urlAfterRedirects, anonymize_ip: true });
      }
    }
    if (event instanceof NavigationEnd) {
      window.scrollTo(0, 0);
      asapScheduler.schedule(() => this.closeMenu());
    }
    this.document.querySelector('body').classList.add('set--in');

    this.document.documentElement.style.setProperty('--vh', `${(window.innerHeight * 0.01).toFixed(2)}px`);

    this.hideBottomBar = !!state?.state?.data['hideBottomNav'];

    window.addEventListener('DOMContentLoaded', () => {
      // const displayMode = window.matchMedia('(display-mode: standalone)').matches ? 'standalone' : 'browser tab';

      this.isStandAlone$.set(window.matchMedia('(display-mode: standalone)').matches);
      // console.log(window.matchMedia('(display-mode: standalone)').matches);
    });
  });

  constructor() {
    this.initializeServiceWorker();
    this.initializeFirebase();
    this.initializeApp();
    this.initializeApplicationInsights(environment.appInsightsKey);
    FirebaseMessaging.addListener('notificationActionPerformed', event => {
      if ((event.notification.data as any)?.['routerLink']?.length > 0) {
        this.router.navigate(JSON.parse((event.notification.data as any)?.['routerLink']));
      } else if ((event.notification.data as any)?.['url']?.length > 0) {
        window.open((event.notification.data as any)?.['url'], '_blank');
      }
    });
  }

  async ngOnInit() {
    if (Capacitor.getPlatform() === 'ios') {
      const status = await this.appTracking.getStatus();
      if (status.status === 'notDetermined') {
        const response = await this.appTracking.requestPermission();
      }
    }

    // TODO refactor as Signal
    if (Capacitor.isPluginAvailable('Keyboard')) {
      Keyboard.addListener('keyboardWillShow', info => {
        //console.log('Keyboard will show');
        this.keyboardVisible = true;
        this.cd.detectChanges();
      });

      Keyboard.addListener('keyboardDidShow', info => {
        //console.log('keyboard did show with height:', info.keyboardHeight);
        const activeElement = document.activeElement;
        const activeElementIsAField = activeElement?.tagName === 'INPUT' || activeElement?.tagName === 'TEXTAREA';

        //console.log('activeElement', activeElement);

        if (this.platform.IOS && activeElementIsAField) {
          let y = activeElement?.getBoundingClientRect().top + window.scrollY - 300;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      });

      Keyboard.addListener('keyboardWillHide', () => {
        console.log('keyboard will hide');
      });

      Keyboard.addListener('keyboardDidHide', () => {
        console.log('Keyboard did hide');
        this.keyboardVisible = false;
        this.cd.detectChanges();
      });
    }
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      console.log(event);
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split('/mijn-rova').pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });

    App.addListener('backButton', ({ canGoBack }) => {
      if (!canGoBack) {
        App.exitApp();
      } else {
        window.history.back();
      }
    });
  }

  public async initializeFirebase(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      return;
    }
    initializeApp(environment.firebase);
  }

  public initializeServiceWorker() {
    // if (Capacitor.isNativePlatform() && 'serviceWorker' in navigator) {
    //   navigator.serviceWorker.getRegistrations().then(registrations => {
    //     for (const registration of registrations) {
    //       registration.unregister();
    //     }
    //   });
    // }
  }

  public initializeApplicationInsights(instrumentationKey: string) {
    var angularPlugin = new AngularPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router },
        },
      },
    });
    appInsights.loadAppInsights();
  }

  toggleMenu() {
    this.store.dispatch(navActions.ToggleMenu());
  }

  closeMenu() {
    this.store.dispatch(navActions.CloseMenu());
  }

  openMenu() {
    this.store.dispatch(navActions.OpenMenu());
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData;
  }

  private updateRoot(menuActive: boolean) {
    menuActive
      ? this.renderer.addClass(this.document.body, 'main-nav--active')
      : this.renderer.removeClass(this.document.body, 'main-nav--active');
  }

  signOut() {
    this.store.dispatch(SignOut({}));
  }
}
