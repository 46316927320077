import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

// const appInsights = new ApplicationInsights({
//   config: {
//     instrumentationKey: environment.appInsightsKey,
//     /* ...Other Configuration Options... */
//   },
// });
// appInsights.loadAppInsights();
// appInsights.trackPageView();

export function getBaseUrl() {
  return '/';
}

const providers = [{ provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }];

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic(providers).bootstrapModule(AppModule);

if (document.readyState === 'complete') {
  function bootstrap() {
    bootstrap();
  }

  if (document.readyState === 'complete') {
    bootstrap();
  } else {
    document.addEventListener('DOMContentLoaded', bootstrap);
  }
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
