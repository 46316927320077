import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-404',
  template: `
    <article class="error-404 section-inset-y">
      <div class="container text-center pt-topbar">
        <h1>{{ 'error-404-title' | fromDictionary }}</h1>
        <p class="text-max-width mx-auto">{{ 'error-404-text' | fromDictionary }}</p>
        <div class="error-404__content text-center mt-4">
          <a [routerLink]="'/'" class="button button--primary">Terug naar home</a>
        </div>
      </div>
    </article>
  `,
})
export class NotFoundComponent {
  code = 404;

  constructor(private router: Router) {
    const statusCodeFromUrl = parseInt(this.router.url.replace(/\//g, ''));
    this.code = statusCodeFromUrl ? statusCodeFromUrl : 404;
  }

  ngOnInit(): void {}
}
