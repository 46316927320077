<div class="app" [class.set--ios]="platform.IOS" [class.set--standalone]="isStandAlone$()">
  <div class="app__inner" [class.anim--in]="loading$()">
    <app-topbar
      (toggleOverlay)="toggleMenu()"
      (closeOverlay)="closeMenu()"
      (openOverlay)="openMenu()"
      *ngIf="loaded$()"
      [isSignedIn]="signedIn$()"
      [menuActive]="menuOpen$()"
      [address]="address$()"
      [hasDepositsService]="hasInsights$()"
      [menu]="('mijnrova-hoofdmenu' | fromMenu)?.error ? [] : ('mijnrova-hoofdmenu' | fromMenu)"
      [quickMenu]="('bottom-bar-menu' | fromMenu)?.error ? [] : ('bottom-bar-menu' | fromMenu)"
      [quickFooterMenu]="('uitklap-meta-menu' | fromMenu)?.error ? [] : ('uitklap-meta-menu' | fromMenu)"
    ></app-topbar>

    <div class="app__router" [@routerFade]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
    <!-- <app-footer [domain]="domainName" [address]="address$()" [isSignedIn]="signedIn$()" *ngIf="loaded$()"></app-footer> -->
  </div>
  <app-loader-indicator [loading]="loading$()"></app-loader-indicator>
  <fstr-cookie-notice
    [title]="'cookie-titel' | fromDictionary"
    *ngIf="loaded$() && this.trackingStatus$() === 'authorized'"
    [text]="'cookie-tekst' | fromDictionary"
    [buttonAccept]="'cookie-accepteer-knop-tekst' | fromDictionary"
    [buttonReject]="'cookie-afwijzen-knop-tekst' | fromDictionary"
  ></fstr-cookie-notice>
  <app-bottom-bar
    [address]="address$()"
    [hasDepositsService]="hasInsights$()"
    [menu]="('bottom-bar-menu' | fromMenu)?.error ? [] : ('bottom-bar-menu' | fromMenu)"
    *ngIf="loaded$() && !keyboardVisible && !hideBottomBar"
  ></app-bottom-bar>
</div>

<app-grid-overlay *ngIf="testGridEnabled"></app-grid-overlay>
