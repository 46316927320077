<footer class="footer">
  <ul class="footer__nav-list container fs-body-xxs pt-3 pt-md-5 d-flex pb-3">
    <li class="footer__nav-list__item">{{ year }} &copy; {{ 'meta-titel-suffix' | fromDictionary }}</li>
    <li class="footer__nav-list__item">
      <button (click)="openCookie()" class="text-secondary">{{ 'cookie-settings' | fromDictionary }}</button>
    </li>
    <li class="footer__nav-list__item">
      <a class="text-secondary" [href]="'inzicht-footer-link-disclaimer' | fromDictionary" target="_blank">Disclaimer</a>
    </li>
    <li class="footer__nav-list__item">
      <a class="text-secondary" [href]="'inzicht-footer-link-veelgestelde-vragen' | fromDictionary" target="_blank">Veelgestelde vragen</a>
    </li>
    <li class="footer__nav-list__item text-secondary">
      <button *ngIf="addressKnown" (click)="signOut()">Uitloggen</button>
      <a
        *ngIf="!addressKnown"
        [routerLink]="['/aanmelden/inloggen']"
        [queryParams]="{ returnUrl: '/dashboard' }"
        class="text-decoration-none"
      >
        Inloggen
      </a>
    </li>
  </ul>
</footer>
