// Env
import { environment } from '../environments/environment';
// Angular
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, isDevMode, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Store
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, CustomSerializer, appEffects, metaReducers } from './store';
// Components
import { AppComponent } from './app.component';
import { NotFoundComponent } from './404.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
// Foster Modules
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { CookieConfig, CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';
import { IconModule } from '@teamfoster/sdk/icon';
import { MenuNgrxModule } from '@teamfoster/sdk/menu-ngrx';
import { DynamicFormConfig, DynamicFormModule } from '@teamfoster/dynamic-forms';
// Locale
import localeNl from './nl';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
// not used in production
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FormModule } from './form/form.module';
import { AuthModule } from './auth/auth.module';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { FooterComponent } from './standalone/footer/footer.component';
import { TopbarComponent } from './standalone/topbar/topbar.component';
import { BottomBarComponent } from './standalone/bottom-bar/bottom-bar.component';
import { NotificationsModule } from './notifications/notifications.module';
import { DynamicHooksModule, HookParserEntry } from 'ngx-dynamic-hooks';
import { ContentSliderComponent } from './content-slider/components/content-slider/content-slider.component';
import { FormFileInputComponent } from './form/components/form-file-input/form-file-input.component';
import { FormHiddenFieldComponent } from './form/components/form-hidden-field/form-hidden-field.component';
import { FormBasicInputComponent } from './form/components/form-basic-input/form-basic-input.component';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@angular/cdk/platform';
import { SettingsModule } from './settings/settings.module';
import { ProvideImageProcessor } from '@teamfoster/sdk/image';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

const cookieConfig: CookieConfig = {
  analyticsCode: 'G-8M1L6SHHS0',
  strippedContentPlaceholder: 'Deze inhoud wordt niet getoond omdat u geen marketing cookies hebt geaccepteerd',
  buttonSettings: 'Instellingen',
  buttonAcceptAll: 'Alles accepteren',
  buttonClose: 'Sluiten',
  strippedContentButton: 'Cookie-instellingen wijzigen',
  types: {
    analytical: true,
    functional: true,
    marketing: true,
  },
  showSettings: true,
};

const dynamicFormConfig: DynamicFormConfig = {
  formControls: {
    multipleFiles: FormFileInputComponent,
    hidden: FormHiddenFieldComponent,
    basic: FormBasicInputComponent,
  },
  genericInputErrorMessage: 'Het formulier is niet correct ingevuld, controleer de velden en probeer nogmaals.',
  genericServerErrorMessage:
    'Er is iets fout gegaan bij het versturen van het formulier, probeer het later nogmaals. Blijft het probleem voordoen neem dan contact met ons op.',
  //formControls,
  formControlErrors: {
    invalidPassNumber: 'Het door u ingevulde pasnummer is onjuist ',
  },
};

// This automatically creates SelectorHookParsers for each listed component:
const componentParsers: Array<HookParserEntry> = [
  { component: ContentSliderComponent },
  // ...
];

registerLocaleData(localeNl, 'nl');

const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
};

export const initializeApp = (swUpdate: SwUpdate): (() => Promise<any>) => {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        // Check if Service Worker is supported by the Browser
        if (swUpdate.isEnabled) {
          const isNewVersion = await swUpdate.checkForUpdate();
          // Check if the new version is available
          console.log(isNewVersion);
          if (isNewVersion) {
            const isNewVersionActivated = await swUpdate.activateUpdate();
            // Check if the new version is activated and reload the app if it is
            if (isNewVersionActivated) window.location.reload();
            resolve(true);
          }
          resolve(true);
        }
        resolve(true);
      } catch (error) {
        console.log(error);
        window.location.reload();
      }
    });
  };
};
@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    FormModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    AuthModule,
    FooterComponent,
    TopbarComponent,
    BottomBarComponent,
    NotificationsModule,
    // NGRX
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    SettingsModule,
    EffectsModule.forRoot(appEffects),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    // Foster Modules
    DictionaryNgrxModule.forRoot({}),
    CookieNgrxModule.forRoot(cookieConfig),
    DynamicFormModule.forRoot(dynamicFormConfig),
    IconModule.forRoot({ spritesheetUrl: '/mijn-rova/assets/dist/svg/symbol/svg/sprite.symbol.svg' }),
    MenuNgrxModule.forRoot({}),
    ServiceWorkerModule.register('ngsw-worker.js?v=17', {
      enabled: !isDevMode() && !Capacitor.isNativePlatform(),
      // enabled: !Capacitor.isNativePlatform(),
      // enabled: false,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:3000',
      // registrationStrategy: 'registerImmediately',
    }),
    DynamicHooksModule.forRoot({ globalParsers: componentParsers }),
  ],
  providers: [
    ProvideImageProcessor({}),
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    { provide: APP_BASE_HREF, useValue: '/mijn-rova' },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill', color: 'accent', floatLabel: 'always' } },
    { provide: ErrorHandler, useClass: environment.production ? ApplicationinsightsAngularpluginErrorService : ErrorHandler },
    // { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [SwUpdate], multi: true },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
