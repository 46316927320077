import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { IconModule } from '@teamfoster/sdk/icon';
import { Router, RouterModule } from '@angular/router';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { ButtonModule } from '@teamfoster/sdk/button';
import { A11yModule } from '@angular/cdk/a11y';

import * as fromMenu from '@teamfoster/sdk/menu-ngrx';
import { Store } from '@ngrx/store';
import { Address } from 'src/app/addresses/models';
import { debounceTime, fromEvent } from 'rxjs';
import { SignOut, getAuthSignedIn } from 'src/app/auth/store';
import { ClearAddress, getAddress } from 'src/app/addresses/store';
import { DeleteSubscriptions, getNotificationToken } from 'src/app/notifications/store';
import { Location } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { ClearCompletedProfile } from 'src/app/settings/store';

@Component({
  selector: 'app-topbar',
  standalone: true,
  imports: [CommonModule, IconModule, RouterModule, DictionaryNgrxModule, ButtonModule, A11yModule],
  templateUrl: './topbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopbarComponent implements OnInit {
  public platform = inject(Platform);
  private store = inject(Store);
  private router = inject(Router);
  private platformId = inject(PLATFORM_ID);
  private cd = inject(ChangeDetectorRef);
  signedIn$ = this.store.selectSignal(getAuthSignedIn);
  address$ = this.store.selectSignal(getAddress);
  FCMToken$ = this.store.selectSignal(getNotificationToken);

  @Input() menu: fromMenu.MenuItem[] = [];
  @Input() quickMenu: fromMenu.MenuItem[] = [];
  @Input() quickFooterMenu: fromMenu.MenuItem[] = [];

  @Input() isSignedIn: boolean = false;
  @Input() address?: Address | null;
  @Input() hasDepositsService: boolean = false;

  @Input() menuActive: boolean = false;
  @Output() toggleOverlay = new EventEmitter<void>();
  @Output() closeOverlay = new EventEmitter<void>();
  @Output() openOverlay = new EventEmitter<void>();

  fixed = false;
  lastScrollTop = 0;
  scrollDirection: 'up' | 'down' = 'up';

  private location = inject(Location);

  // Check of inzamelgegevens dashboard getoond mag worden, voor nu mag dat alleen in zwolle
  get filteredMenuItems() {
    // return this.menu.filter(a => !(a.url === '/inzicht' && this.address?.municipalityId !== 21));
    return this.menu.filter(a => !(a.url === '/inzicht' && !this.hasDepositsService));
  }

  get filteredQuickMenuItems() {
    return this.PlatformMenuItems.filter(a => !(a.url === '/inzicht' && !this.hasDepositsService));
  }
  get filteredQuickFooterMenuItems() {
    return this.quickFooterMenu.filter(a => !(a.url === '/inzicht' && !this.hasDepositsService));
  }

  get PlatformMenuItems() {
    return this.quickMenu.filter(a => !this.platform.ANDROID && !this.platform.IOS && !(a.url === '/menu'));
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      fromEvent(window, 'scroll')
        .pipe(debounceTime(20))
        .subscribe(a => {
          // Set fixed
          if (window.scrollY > 10) {
            this.fixed = true;
          } else {
            this.fixed = false;
            this.scrollDirection = 'up';
            this.cd.detectChanges();
          }

          // Set scrolldireciton
          const st = window.scrollY > 0 ? window.scrollY : 0;
          this.scrollDirection = st < this.lastScrollTop ? 'up' : 'down';
          this.lastScrollTop = st;

          this.cd.detectChanges();
        });
    }
  }

  isHomeUrl(url: string | undefined) {
    const homeMatches = ['#', '/', ''];
    return homeMatches.includes(url || '');
  }

  @HostListener('document:keyup.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeOverlay.emit();
  }

  // copied from user-settings.ts
  formattedAddressLines() {
    if (!this.address$()) {
      return;
    }

    const { street, city, houseNumber, addition, postalCodeFull } = this.address$()!;
    return [`${street} ${houseNumber} ${addition || ''}`, `${[postalCodeFull, city].join(' ')}`];
  }
  signOut() {
    if (this.signedIn$()) {
      this.store.dispatch(SignOut({}));
      //this.store.dispatch(ClearAddress({}));
      this.store.dispatch(DeleteSubscriptions({ token: this.FCMToken$() }));
    } else {
      this.store.dispatch(ClearCompletedProfile());
      this.store.dispatch(ClearAddress({ redirect: true }));
      this.store.dispatch(DeleteSubscriptions({ token: this.FCMToken$() }));
    }
  }
  routeBackIfActive(url: string) {
    //const state = <any>this.location.getState();
    //const isActive = this.router.url === url;
    //if (state && state?.navigationId > 1 && isActive) {
    //  this.location.back();
    //}
  }
}
